import React, {useState} from 'react'
import {Redirect} from "react-router-dom";
import { Dropdown, Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {Image, Grid, Message} from 'semantic-ui-react';
import {Badge, Card, Button, Tooltip, Input } from 'antd';
import {CheckTokenValidation, ChangePassword} from './register_actions'

const PasswordPolicy = require('voipex-password-policy')

function Registrazione({match, CheckTokenValidation, ChangePassword, register}){

    
      

      const [NeedCheckToken, SetNeedCheckToken] = useState(true)
      const [NewPassword, SetNewPassword] = useState('a')
      const [ConfirmNewPassword, SetConfirmNewPassword] = useState('a')
      const [PolicyErrors, SetPolicyErrors] = useState([])
      const [PasswordValid, SetPasswordValid] = useState(false)
      const [PasswordNotequals, SetPasswordNotequals] = useState(false)

      function SaveNewPassword(e){
        SetNewPassword(e.target.value)
      }

      function SaveConfirmNewPassword(e){
        SetConfirmNewPassword(e.target.value)
      }


      // Elenco errori generate

      async function Register(){

        const policy = new PasswordPolicy()
        policy.minimumLength = 8
        policy.maximumLength = 14
        policy.minimumNumberOfUpperLetters = 1
        policy.minimumNumberOfDigits = 1

        var ValidatePassword = await policy.validate(NewPassword)

        if(ValidatePassword){
          SetPasswordValid(true)
          SetPolicyErrors(policy.errors)
        } else {
          SetPasswordValid(false)
          SetPolicyErrors(policy.errors)
        }

        if(PasswordValid){
          if(ConfirmNewPassword == NewPassword){
            SetPasswordNotequals(false)
            ChangePassword(register.token_status.user_id, NewPassword)
          } else {
            SetPasswordNotequals(true)
          }      
        }

        if(ConfirmNewPassword == NewPassword){
          SetPasswordNotequals(false)
        } else {
          SetPasswordNotequals(true)
        }   


      }

      if(register.password_changed){
        return(<Redirect to="/home" />)
      }
      
      if(register.token_status.isInvalid == true){

        if(NeedCheckToken){
          CheckTokenValidation(match.params.token)
          SetNeedCheckToken(false)
        }

        return(
          <div id="registrazione_riquadro_errore">
              <>
                <center>
                  <Image id='Logo' src='https://www.lobra.com/wp-content/uploads/2019/09/logo.svg' />
                </center>
              </>
              <br></br>
              <Message hidden={(register.token_status.isInvalid && register.check_requested == true ? false : true )} id="santa_pazienza_errore" color='red'>The Link is Invalid!</Message>
              <Message hidden={(register.check_requested ? true : false)} id="santa_pazienza_errore" color='red'>Checking Link Validation...</Message>
          </div>
        )
      } else {
        return(
          <div id="registrazione_riquadro">

            <div>
                <>
                <center>
                  <Image id='Logo' src='https://www.lobra.com/wp-content/uploads/2019/09/logo.svg' />
                </center>
                </>
  
                <>
                  <h4 id='titolo_change_pw' class="ui header">Signup New User</h4>
                </>
  
                <>
                  <div className='login_class'>
                  <h5 class="ui header">Name</h5>
                    <div class="ui input">
                      <Input value={register.token_status.name} disabled={true} id='input_registrazione'/>
                    </div>
                  </div>
                </>
  
                <>
                  <div className='login_class'>
                    <h5 class="ui header">Email</h5>
                    <div class="ui input">
                           <Input value={register.token_status.email} disabled={true} id='input_registrazione'/>
                      </div>
                  </div>
                </>
  
                <>
                  <div className='login_class'>
                  <h5 class="ui header">New Password</h5>
                    <div class="ui input">
                      <Tooltip placement="right" title="The password must be at least 8 characters long and must contain at least one capital letter and a number">
                          <Input onKeyUp={SaveNewPassword} disabled={false} id='input_registrazione' placeholder={'New Password'} type="password"/>
                      </Tooltip>
                    </div>
                  </div>
                </>
  
                <>
                  <div className='login_class'>
                  <h5 class="ui header">Confirm New Password</h5>
                    <div class="ui input">
                        <Input onKeyUp={SaveConfirmNewPassword} disabled={false} id='input_registrazione' placeholder={'Confirm New Password'} type="password"/>
                    </div>
                  </div>
                </>
                

                  <Card id="card_errors_signup" style={{ 'padding-bottom': '10px',  'padding-top': '10px'}} bordered={true} hidden={(PolicyErrors.length == 0 && !PasswordNotequals) ? true : false }>
                    {
                      PolicyErrors.map((Errore) => {
                        if(Errore.validator == 'MinimalLength'){
                          return (<div><Badge status="error" text={'Minimal Length 8 Characters'} /><br></br></div>)
                        }
                        if(Errore.validator == 'minimumNumberOfUpperLetters'){
                          return (<div><Badge status="error" text={'At Least 1 Capital Letter'} /><br></br></div>)
                        }
                        if(Errore.validator == 'minimumNumberOfDigits'){
                          return (<div><Badge status="error" text={'At Least 1 Digit'} /><br></br></div>)
                        }
                        if(Errore.validator == 'MaximalLength'){
                          return (<div><Badge status="error" text={'Maximum Length 14 Characters'} /><br></br></div>)
                        }
                      })                  
                    }
                    {
                      (PasswordNotequals) ? <div><Badge status="error" text={'Passwords are not Equals'} /><br></br></div> : <div></div>
                    }
                    

                  </Card>

                <>
                  <center>
                    <Button disabled={false} onClick={Register} id='bottone_login_signup'>Signup</Button>
                  </center>
                </>
                <br></br>
            </div>

          </div>
      );
      }

       
  }

const mapStateToProps = state => ({
  register: state.register,
})

const mapDispatchToProps = dispatch => ({
  CheckTokenValidation: (token) => dispatch(CheckTokenValidation(token)),
  ChangePassword: (user_id, new_password) => dispatch(ChangePassword(user_id, new_password)), 
})

export default {
  Main: connect(mapStateToProps, mapDispatchToProps)(Registrazione),
}









