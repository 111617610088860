
var request = require('request-promise');
var encrypter = require('object-encrypter');

var engine = encrypter('A63IHD7DR7EW82JS89838U4JJ43IS99288HD7HD7D', {ttl: false});

async function EncryptOptionsRequest(data){
    var newDataObj = data

    // GET
    if(data.method === 'GET'){      
        Object.entries(data.qs).map(async (key, value) => {
            newDataObj.qs[key[0]] = await engine.encrypt({encrypted: data.qs[key[0]]}, 10000);
        })           
    } else {
        Object.entries(data.body).map(async (key, value) => {
            newDataObj.body[key[0]] = await engine.encrypt({encrypted: data.body[key[0]]}, 10000);
        })
    }

    return newDataObj 
    
}



 const serverprod = "https://helpdesk.lobra.com/" //prod
 //const serverprod = "https://lobra-staging.azurewebsites.net/" //Staging
 //const serverprod = "http://localhost:2222" //Locale

const odoo_api = {

    try_login_odoo: async function (username, password) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'GET',
                uri:  serverprod + '/odoo/login_odoo',
                qs: {
                    username: username,
                    password: password
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)         
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    get_all_mytickets: async function (id, su_ids, isAdmin) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'GET',
                uri:  serverprod + '/odoo/get_tickets_helpdesk',
                qs: {
                    id: id,
                    su_ids: su_ids,
                    is_admin: isAdmin
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)  
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    get_single_ticket: async function (id) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'GET',
                uri:  serverprod + '/odoo/get_single_ticket_helpdesk',
                qs: {
                    id: id
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)  
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    get_token_info: async function (token) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'GET',
                uri:  serverprod + '/odoo/get_token_info',
                qs: {
                    token: token
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)  
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    get_possible_status_team: async function (team_id) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'GET',
                uri:  serverprod + '/odoo/get_possible_status_team',
                qs: {
                    team_id: team_id
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)  
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    get_available_code: async function (user_id) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'GET',
                uri:  serverprod + '/odoo/get_available_code',
                qs: {
                    user_id: user_id
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)  
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    get_team_priority: async function (team_id) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'GET',
                uri:  serverprod + '/odoo/get_priority_team',
                qs: {
                    team_id: team_id
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)  
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    get_team_category: async function (team_id) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'GET',
                uri:  serverprod + '/odoo/get_category_team',
                qs: {
                    team_id: team_id
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)  
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    create_ticket: async function (name,team_id,tipologia,user_id,type,tagid,company,display_name,email,descrizione,allegati,crapprovata) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'POST',
                uri:  serverprod + '/odoo/create_ticket',
                body: {
                    name: name,
                    team_id: team_id,
                    tipologia: tipologia,
                    user_id: user_id,
                    type: type,
                    tagid: tagid,
                    company: company,
                    display_name: display_name,
                    email: email,
                    descrizione: descrizione,
                    allegati: allegati,
                    crapprovata: crapprovata
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)  
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    create_chatter_msg: async function (ticket_id, messaggio, user_id) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'POST',
                uri:  serverprod + '/odoo/create_chatter_msg',
                body: {
                    ticket_id: ticket_id,
                    messaggio: messaggio,
                    user_id: user_id   
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)  
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    change_password: async function (user_id, new_password) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'POST',
                uri:  serverprod + '/odoo/change_password',
                body: {
                    user_id: user_id,
                    new_password: new_password,
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)  
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    reset_password: async function (new_password,old_password,email) {
        return new Promise(async function (resolve, reject) {
            var options = {
                method: 'POST',
                uri:  serverprod + '/odoo/reset_password',
                body: {
                    new_password: new_password,
                    old_password: old_password,
                    email: email
                },
                json: true // Automatically parses the JSON string in the response
            };
            var encryptData = await EncryptOptionsRequest(options)
            request(encryptData).then(async function (apiresp) {
                resolve(await engine.decrypt(apiresp).encrypted)  
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },



}

export default odoo_api