import React, {useState} from 'react'
import { connect } from 'react-redux'
import { Switch, Avatar, Tag, message, List, Comment, Collapse, Result, Row, Col, Steps, Table, Space, Button, Upload} from 'antd';
import { Divider, Form, Input,Image, Grid, Message, Dropdown, Header, Segment, Label, TextArea } from 'semantic-ui-react';
import { TryDoLoginOdoo } from "../login_page/login_actions"
import {create_chatter_msg, clear_new_ticket, change_header, get_team_category, create_ticket, get_team_priority, get_available_code, clear_ticket, get_possible_status_team, get_all_mytickets, get_single_ticket} from "./helpdesk_actions"
import {SendOutlined, InboxOutlined , PaperClipOutlined, HourglassOutlined, SyncOutlined, PlusOutlined, SearchOutlined, UploadOutlined, ArrowLeftOutlined, UserOutlined, LoadingOutlined, SmileOutlined, CheckCircleTwoTone} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment-timezone';
import '../antd.css';

moment.tz.setDefault("Europe/Rome")

var natural = require("natural-compare")

var encrypter = require('object-encrypter');
var engine = encrypter('A63IHD7DR7EW82JS89838U4JJ43IS99288HD7HD7D', {ttl: false});

const HelpDeskMainPage = ({login, helpDesk, get_all_mytickets, get_single_ticket, get_possible_status_team, clear_ticket, get_available_code, get_team_priority, create_ticket, get_team_category, change_header, clear_new_ticket, create_chatter_msg}) => {

// TABELLA TICKET CLASSE REACT  [INIZIO]
   
  class TabellaTicket extends React.Component {
    state = {
      bordered: true,
      searchText: '',
      searchedColumn: '',
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
          sortedInfo: sorter,     
        });
    };
  
    getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            id='input_rossi_100p'
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Cerca`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#ea515b' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
      render: text =>
        this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#f4c5c5', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    });
  
    handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    };
  
    handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
    };
  
    render() {
        let { searchText, searchedColumn, sortedInfo} = this.state;
        sortedInfo = sortedInfo || {};

      const columns = [
        {
          title: 'Codice',
          dataIndex: 'id',        
          key: 'id',
          width: '2%', 
          ...this.getColumnSearchProps('id'),
          render: value => <Button codice={value} onClick={open_ticket_details}type="link">{value}</Button>,
          sorter: (a,b) => natural(a.id, b.id), 
          defaultSortOrder: 'descend'
        },
        {
          title: 'Stato',
          dataIndex: 'stato',
          key: 'stato',
          width: '6%',
          render: value => <><center>{value}</center></>,
          filters: this.props.settings.Filtri.stato || null,
          defaultFilteredValue: this.props.settings.PreFiltri.stato || null,
          onFilter: (value, record) => record.stato.indexOf(value) === 0,
          sorter: (a,b) => natural(a.stato, b.stato),
        },
        {
          title: 'Oggetto',
          dataIndex: 'name',
          key: 'name',
          width: '15%',
          ...this.getColumnSearchProps('name'),
          render: value => <div>{value}</div>,
          sorter: (a,b) => natural(a.name, b.name),
        },
        {
          title: 'Owner',
          dataIndex: 'proprietario_ticket',
          key: 'proprietario_ticket',
          width: '10%',
          render: value => <><center>{value}</center></>,
          filters: this.props.settings.Filtri.proprietario_ticket || null,
          defaultFilteredValue: this.props.settings.PreFiltri.proprietario_ticket || null,
          onFilter: (value, record) => record.proprietario_ticket.indexOf(value) === 0,
          sorter: (a,b) => natural(a.proprietario_ticket, b.proprietario_ticket),
        },
        {
          title: 'Priorità',
          dataIndex: 'priorita',
          key: 'priorita',
          width: '7%',
          render: value => <><center>{value}</center></>,
          filters: this.props.settings.Filtri.priorita || null,
          defaultFilteredValue: this.props.settings.PreFiltri.priorita || null,
          onFilter: (value, record) => record.priorita.indexOf(value) === 0,
          sorter: (a,b) => natural(a.priorita, b.priorita),
        },
        {
          title: 'Tipologia',
          dataIndex: 'x_studio_tipologia',
          key: 'x_studio_tipologia',
          width: '6%',
          render: value => <><center>{value}</center></>,
          filters: this.props.settings.Filtri.x_studio_tipologia || null,
          defaultFilteredValue: this.props.settings.PreFiltri.x_studio_tipologia || null,
          onFilter: (value, record) => record.x_studio_tipologia.indexOf(value) === 0,
          sorter: (a,b) => natural(a.x_studio_tipologia, b.x_studio_tipologia),
        },
        {
          title: 'Team',
          dataIndex: 'teamname',
          key: 'teamname',
          width: '11%',
          render: value => <><center>{value}</center></>,
          filters: this.props.settings.Filtri.teamname || null,
          defaultFilteredValue: this.props.settings.PreFiltri.teamname || null,
          onFilter: (value, record) => record.teamname.indexOf(value) === 0,
          sorter: (a,b) => natural(a.teamname, b.teamname),
        },
        {
          title: 'Categoria',
          dataIndex: 'categorie',
          key: 'categorie',
          width: '12%',
          render: value => value.map((categoria) => <Tag color="default">{categoria}</Tag>),
          filters: this.props.settings.Filtri.categorie || null,
          defaultFilteredValue: this.props.settings.PreFiltri.categorie || null,
          onFilter: (value, record) => record.categorie.includes(value),
          sorter: (a,b) => natural(a.categorie, b.categorie),
        },
        {
          title: 'Data Apertura',
          dataIndex: 'create_date',
          key: 'create_date',
          width: '10%',         
          render: value => <><center>{value}</center></>,
          ...this.getColumnSearchProps('create_date'),
          sorter: (a,b) => natural(a.create_date, b.create_date),
        },
        {
          title: 'Data Chiusura',
          dataIndex: 'close_date',
          key: 'close_date',
          width: '10%',
          render: value => <><center>{value}</center></>,
          ...this.getColumnSearchProps('close_date'),
          sorter: (a,b) => natural(a.close_date, b.close_date),
        },
        
      ];
      return <Table columns={columns} dataSource={this.props.dati} onChange={this.handleChange}/>;
    }
  }

// TABELLA TICKET CLASSE REACT  [FINE]

 
    const [SectionTicketsList, SetTicketsList] = useState(true);
    const [SectionOpenTicket, SetSectionOpenTicket] = useState(false);
    const [SectionTicketDetails, SetTicketDetails] = useState(false);
    const [NumeroTicket, SetNumeroTicket] = useState('');
    const [NeedGetTickets, SetNeedGetTickets] = useState(true)
    const [NeedGetSingleTicket, SetGetSingleTicket] = useState(true)
    const [NeedGetPossibleStatus, SetGetPossibleStatus] = useState(true)
    const [NeedGetCode, SetGetCode] = useState(true)
    const [NewTicket_CRApprovata, SetNewTicket_CRApprovata] = useState(false)
    const [NewChatter_Messaggio, SetNewChatter_Messaggio] = useState('')
    const [NewTicket_Oggetto, SetNewTicket_Oggetto] = useState('')
    const [NewTicket_Descrizione, SetNewTicket_Descrizione] = useState('')
    const [NewTicket_Team, SetNewTicket_Team] = useState('')
    const [NewTicket_Categoria, SetNewTicket_Categoria] = useState('')
    const [NewTicket_Priorita, SetNewTicket_Priorita] = useState('')
    const [NewTicket_Tipologia, SetNewTicket_Tipologia] = useState('')
    const [NewTicket_InviaEnable, SetNewTicket_InviaEnable] = useState(true)
    const [NewTicket_Allegati, SetNewTicket_Allegati] = useState({}) 
    const [LastUserID, SetUserID] = useState(0)
    const [NewTicket_FileList, SetNewTicket_FileList] = useState([])
    const [NeedClearStatus, SetNeedClearStatus] = useState(true)
  
    function open_ticket(){
        change_header('Apertura Nuovo Ticket')    
        SetTicketsList(false)
        SetTicketDetails(false)
        SetSectionOpenTicket(true)
        SetNewTicket_Oggetto('')
        SetNewTicket_Categoria('')
        SetNewTicket_Descrizione('')
        SetNewTicket_Priorita('')
        SetNewTicket_Team('')
        SetNewTicket_Tipologia('')
        SetNewTicket_InviaEnable(true)
        SetGetCode(true)
        SetNewTicket_Allegati({})
    }

    function open_ticket_list(){
        change_header('I Miei Tickets')
        clear_ticket()
        SetSectionOpenTicket(false)
        SetTicketDetails(false)
        SetTicketsList(true)
        SetNeedGetTickets(true)
        SetGetSingleTicket(true)
        SetGetPossibleStatus(true)
    }

    function open_ticket_details(e){
        change_header('Dettaglio Ticket')
        SetNumeroTicket(e.currentTarget.attributes.codice.nodeValue)
        SetSectionOpenTicket(false)      
        SetTicketsList(false)
        SetTicketDetails(true)
    }

    function get_priority(e,{name, value}){   
        SetNewTicket_Team(value)
        SetNewTicket_Categoria('')
        SetNewTicket_Priorita('')
        clear_new_ticket()
        get_team_category(value)
        get_team_priority(value)
        check_invia()
    }

    function setOggetto(e){
        SetNewTicket_Oggetto(e.target.value)
        check_invia()
    }

    function setDescrizione(e){
        SetNewTicket_Descrizione(e.target.value)
        check_invia()
    }

    function setTipologia(e,{name, value}){
        SetNewTicket_Tipologia(value)
        check_invia()
    }

    function setCategoria(e,{name, value}){
        SetNewTicket_Categoria(value)
        check_invia()
    }

    function setPriorita(e,{name, value}){
        SetNewTicket_Priorita(value)
        check_invia()
    }

    function setMessaggioChatter(e,{name, value}){
        SetNewChatter_Messaggio(value)
    }

    function AddAllegato(uid, name, base64){
        var temp_allegati = NewTicket_Allegati
        
        temp_allegati[uid] = {
            name: name,
            base64: base64
        }

        SetNewTicket_Allegati(temp_allegati)
    }

    function RemoveAllegato(uid){
        var temp_allegati = NewTicket_Allegati

        delete temp_allegati[uid]

        SetNewTicket_Allegati(temp_allegati)
    }

    function crea_chatter(){
        if(NewChatter_Messaggio === ''){

        } else {
            create_chatter_msg(NumeroTicket,NewChatter_Messaggio,login.info_login.id)
        }
        
        SetGetSingleTicket(true)
        SetNewChatter_Messaggio('')
    }

    function check_invia(){
        if(NewTicket_Oggetto === '' || NewTicket_Team === '' || NewTicket_Tipologia === '' || NewTicket_Priorita === '' || NewTicket_Categoria === '' || NewTicket_Descrizione === ''){
            SetNewTicket_InviaEnable(true)
        } else {
            SetNewTicket_InviaEnable(false)
        }
    }

    function change_crstatus(){
        SetNewTicket_CRApprovata(!NewTicket_CRApprovata)   
    }

    function crea_ticket(){

            var tempCategoria = []
            NewTicket_Categoria.map((id) => {
                tempCategoria.push(id + '')
            })
         
            create_ticket( 
                NewTicket_Oggetto,
                NewTicket_Team,
                NewTicket_Tipologia,
                login.info_login.self[0],
                NewTicket_Priorita,
                tempCategoria,
                '1',
                NewTicket_Oggetto,
                login.info_login.email,
                NewTicket_Descrizione,
                NewTicket_Allegati,
                NewTicket_CRApprovata
            )
           
    }

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    // Sezione Funzione Filtri
    function getFiltri(settings){
        if(helpDesk.ticket_list.length > 0){
            
                var FilterSettings = {
                    Filtri: {},
                    PreFiltri: {}
                }

                // Per ogni proprietà nel Settings ciclo

                Object.entries(settings).map((key, value) => {

                    //Creo la proprietà nei Filtri e Prefiltri
                    FilterSettings.Filtri[key[0]] = []
                    FilterSettings.PreFiltri[key[0]] = []

                    //Ciclo la Ticket List e Appendo i valori per La Proprietà Selezionata
                    helpDesk.ticket_list.map((Ticket) => {
                        if(Array.isArray(Ticket[key[0]])){
                            Ticket[key[0]].map((value) => {
                                if(FilterSettings.PreFiltri[key[0]].includes(value)){
                                } else {
                                    FilterSettings.PreFiltri[key[0]].push(value)
                                    FilterSettings.Filtri[key[0]].push({ text: value, value: value })
                                }
                            })
                        } else {
                            if(FilterSettings.PreFiltri[key[0]].includes(Ticket[key[0]])){
                            } else {
                                FilterSettings.PreFiltri[key[0]].push(Ticket[key[0]])
                                FilterSettings.Filtri[key[0]].push({ text: Ticket[key[0]], value: Ticket[key[0]] })
                            }
                        }        
                    })

                    
                    
                    // Controllo se la Prorietà del Settings ha per Caso un valore da Escludere o Includere
                    if (settings[key[0]].hasOwnProperty('defiltra') || settings[key[0]].defiltra != null || settings[key[0]].defiltra != undefined) {
                        //settings[key[0]].defiltra
                        //myArray.filter( ( el ) => !toRemove.includes( el ) );
                        FilterSettings.PreFiltri[key[0]] = FilterSettings.PreFiltri[key[0]].filter( ( value ) => !settings[key[0]].defiltra.includes( value ) );

                    } else if (settings[key[0]].hasOwnProperty('prefiltra') || settings[key[0]].prefiltra != null || settings[key[0]].prefiltra != undefined) {
                        var TempPrefiltro = []
                        settings[key[0]].prefiltra.map((valore) => {
                            if(FilterSettings.PreFiltri[key[0]].includes(valore)){
                                TempPrefiltro.push(valore)
                            }
                        })  
                        FilterSettings.PreFiltri[key[0]] = TempPrefiltro
                    } else {
                        FilterSettings.PreFiltri[key[0]] = []   
                    }

                    // Rendoin Disinct il Filtro e PreFiltro
                    FilterSettings.Filtri[key[0]] = [...new Set(FilterSettings.Filtri[key[0]])]
                    FilterSettings.PreFiltri[key[0]] = [...new Set(FilterSettings.PreFiltri[key[0]])]

                })
                
                return FilterSettings
        } else {
            var FilterSettings = {
                Filtri: {},
                PreFiltri: {}
            }
            return FilterSettings
        }
    }

    class UploadFileLobra extends React.Component {

        handleChange = ({file, fileList}) => {
            const { status } = file;
            if (status === 'uploading') {
                if(fileList.length > 2){
                      message.error(`"${file.name}" non può essere Caricato, il limite totale degli allegati è 2`);
                } else {
                    if(file.size > 3000000) {
                        message.error(`"${file.name}" non può essere Caricato, dimensione massima consentitita 3MB`);
                    } else {
                          message.success(`"${file.name}" è Pronto per essere Caricato!.`);
                          SetNewTicket_FileList(fileList)
                          getBase64(file.originFileObj, imageUrl =>
                                  AddAllegato(file.uid, file.name, imageUrl.split(',')[1])
                          );
                    }
                }
            }
            if (status === 'removed') {
                SetNewTicket_FileList(fileList)
                RemoveAllegato(file.uid)
            }
        }

        render(){
            const { Dragger } = Upload;

            var props_up = {
                name: 'file',
                multiple: true,
                fileList: null || NewTicket_FileList,
                method: 'get',
                action: 'https://helpdesk.lobra.com/upload_file'
            };

            return (
                <Dragger {...props_up} onChange={this.handleChange}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Clicca o Trascina un file qui, per Caricarlo!</p>
                    <p className="ant-upload-hint">( Max 2 files/3MB )</p>
                </Dragger>
            )
        }
    }

    if(login.info_login.is_loggedin){


        if(login.info_login.id != LastUserID || NeedClearStatus == false){
            clear_ticket()
            open_ticket_list()
            SetUserID(login.info_login.id)
            SetNeedClearStatus(true)
        }

        // Sezione Apri nuovo Ticket
        if(SectionOpenTicket){

           

            
           
              const tipologia = [
                { key: 1, text: 'Incident', value: 'Incident' },
                { key: 2, text: 'Change Request', value: 'Change Request' },
              ]
            
            if(NeedGetCode){
                get_available_code(login.info_login.user_id[0])
                SetGetCode(false)
            }

           
                if(helpDesk.creating_ticket_done){
                    return(<div id='successo'>
                            <Result
                        status="success"
                        title="Il ticket è stato creato correttamente!"
                        subTitle={"Numero del Ticket:  " + helpDesk.numero_ticket_creato}
                        extra={[
                        <Button onClick={open_ticket_list} key="buy">I miei ticket</Button>,
                        ]}
                    />,
             
                    </div>)

                } else {
                    if(helpDesk.loading_creating) {
                        return(<div >
                            <SyncOutlined id="caricamento_icona" style={{ fontSize: 48, color: "red" }} spin />
                            <h3 id="caricamento_scritta">Creazione Ticket in Corso...</h3>
                           </div>)
                    } else {
                    return(<div>  
                        <Row justify="start">
                            <Col flex={'1%'} justify="start"></Col>
                            <Col flex={'auto%'} justify="start">
                                <Button onClick={open_ticket_list}type="primary" danger icon={<ArrowLeftOutlined />}>I miei Ticket</Button>
                            </Col>     
                        </Row>
                    <br></br>
                    <br></br>


                    <Row justify="center">
                        <Col flex={'3%'}></Col>
                        <Col flex={'93%'}>
                            <Row justify="start">
                                <Col flex={'9%'}>
                                    <h4 id='helpdeskteam_label'>* Helpdesk Team</h4>                                
                                </Col>
                                <Col flex={'18.7%'}>
                                    <Dropdown id='input_rossi_100p' selection options={(login.info_login.isInternal ? [...helpDesk.code, { key: '9001', text: 'ITSupport', value: 4 }] : helpDesk.code)} onChange={get_priority}/>
                                </Col>
                                <Col flex={'auto'}></Col>
                            </Row>              
                        </Col>
                        <Col flex={'4%'}></Col>
                    </Row>
                    <br></br>
                    <Row justify="center">
                        <Col flex={'3%'}></Col>

                        <Col flex={'93%'}>
                            <Row justify="start">
                                <Col flex={'9%'}>
                                    <h4 id=''>* Oggetto</h4>
                                </Col>
                                <Col flex={'91%'}>
                                    <Form><TextArea onKeyUp={setOggetto} id="input_rossi_100p" rows={1} placeholder='Oggetto' /></Form>
                                </Col>
                            </Row>                           
                        </Col>
                        <Col flex={'4%'}></Col>
                    </Row>
                    <br></br>

                       
                    <Row justify="center">
                        <Col flex={'3%'}></Col>
                            
                        <Col flex={'25%'}>
                            <Row justify="start">
                                <Col flex={'33%'}>
                                    <h4 id='priorita'>* Priorità</h4>
                                </Col>
                                <Col flex={'auto'}>
                                <Dropdown id='input_rossi_100p' selection options={helpDesk.priorita} onChange={setPriorita}/>
                                </Col>
                                <Col flex={'5%'}></Col>
                            </Row>              
                        </Col>

                        <Col flex={'25%'}>
                            <Row justify="start">
                                <Col flex={'22%'}>
                                    <h4 id='categoria'>* Categoria</h4>
                                </Col>
                                <Col flex={'73%'}>
                                    {(helpDesk.categoria === [] ? <Dropdown id='input_rossi_100p' className="" selection multiple search value={null} options={null} onChange={null}/> : <Dropdown id='input_rossi_100p' className="" selection multiple search options={helpDesk.categoria} onChange={setCategoria}/> )} 
                                </Col>
                                <Col flex={'5%'}></Col>
                            </Row>              
                        </Col>
                            
                        <Col flex={'25%'}>
                            <Row justify="start">
                                <Col flex={'22%'}>
                                    <h4 id='tipologia'>* Tipologia</h4>
                                </Col>
                                <Col flex={'auto'}>
                                    <Dropdown id='input_rossi_100p' className="" selection options={tipologia} onChange={setTipologia}/>                                </Col>
                                <Col flex={'4%'}></Col>
                            </Row>              
                        </Col>

                        <Col flex={'18%'}>
                            <Row justify="start">
                                <Col flex={'45%'}>
                                    <h4 hidden={(NewTicket_Team === 5 && NewTicket_Tipologia === 'Change Request' ? false : true)} id='helpdeskteam_label'>CR Approvata</h4>
                                </Col>
                                <Col flex={'auto'}>
                                    <Switch hidden={(NewTicket_Team === 5 && NewTicket_Tipologia === 'Change Request' ? false : true)} onChange={change_crstatus} />   
                                </Col>
                                <Col flex={'3%'}></Col>
                            </Row>              
                        </Col>

                        <Col flex={'4%'}></Col>   
                    </Row>
                    <br></br>

                    <Row justify="center">
                        <Col flex={'3%'}></Col>
                        <Col flex={'93%'}>
                            <Row justify="start">
                                <Col flex={'9%'}>
                                    <h4 id='helpdeskteam_label'>* Descrizione</h4>
                                </Col>
                                <Col flex={'auto'}>
                                    <Form><TextArea onKeyUp={setDescrizione} id="input_rossi_100p" rows={8} text={NewTicket_Descrizione} placeholder='Descrizione' /></Form>
                                </Col>
                            </Row>              
                        </Col>
                        <Col flex={'4%'}></Col>
                    </Row>
                    <br></br>

                    <Row justify="center">
                        <Col flex={'3%'}></Col>
                        <Col flex={'93%'}>
                            <Row justify="start">
                                <Col flex={'9%'}></Col>
                                <Col flex={'auto'}>
                                    <div>
                                        <UploadFileLobra />
                                    </div>
                                </Col>
                            </Row>              
                        </Col>
                        <Col flex={'4%'}></Col>
                    </Row>
                    <br></br>

                    <Row justify="start">
                        <Col flex={'100px'}></Col>
                        <Col flex={'auto'}></Col>
                        <Col flex={'150px'}><Button disabled={NewTicket_InviaEnable} onClick={crea_ticket} id='bottone_nuovo_ticket' type="primary" danger> Invia</Button></Col>
                        <Col flex={'100px'}></Col>
                    </Row>
                    <br></br>

                </div>)
                    }
            }
                    
            
        }

        // Sezione Apri Lista Tickets
        if(SectionTicketsList){

            if(NeedGetTickets){
                get_all_mytickets(login.info_login.self[0], login.info_login.supervisore_users, login.info_login.isHelpDeskAdmin)
                SetNeedGetTickets(false)
            }

            return (
                <div>
                <Row justify="start">
                    <Col flex={'1%'} justify="start"></Col>
                    <Col flex={'auto%'} justify="start">
                        <Button onClick={open_ticket} type="primary" danger icon={<PlusOutlined /> }> Nuovo Ticket </Button>         
                    </Col>     
                </Row>

                <br></br>
                <br></br>
                    
                <Row justify="center">
                    <Col flex={'99%'}>
                        <div>
                            <TabellaTicket dati={helpDesk.ticket_list} settings={getFiltri({
                                stato: {
                                    defiltra: ['Chiuso']
                                },
                                priorita: {},
                                proprietario_ticket:{
                                    prefiltra: [login.info_login.name]
                                },
                                x_studio_tipologia:{},
                                teamname: {},
                                categorie:{},
                                })} />
                        </div>               
                    </Col>     
                </Row>
            
                </div>
                )
        }

        // Sezione Dettaglio Ticket
        if(SectionTicketDetails){

        const { Step } = Steps;

        if(NeedGetSingleTicket){
            get_single_ticket(NumeroTicket)
            SetGetSingleTicket(false)
        }

            if(helpDesk.single_ticket_done){

                if(NeedGetPossibleStatus){
                    get_possible_status_team(helpDesk.single_ticket[0].team_id[0])
                    SetGetPossibleStatus(false)
                }

                if(helpDesk.get_possible_status_done){

                    var step
                    var init_status = 'wait'

                    var def_status = []

                    for (step = helpDesk.possible_status.length - 1; step >= 0; step--) {
                        if(helpDesk.possible_status[step][1] === helpDesk.single_ticket[0].stage_id[1]){
                            def_status.push({
                                ...helpDesk.possible_status[step],
                                status: 'process',
                            })
                            init_status = 'finish'
                        } else {
                            def_status.push({
                                ...helpDesk.possible_status[step],
                                status: init_status
                            })
                        }
                    }

                    var dominio = 'https://lobra.odoo.com' //Prod
                   var bedominio = 'https://helpdesk.lobra.com/' //Prod

                   //var dominio = 'https://lobra-17-0-staging-16131801.dev.odoo.com' //Staging
                   //var bedominio = 'https://lobra-staging.azurewebsites.net/' //Staging

                   //var bedominio = 'http://localhost:2222' //Locale

                    const { Panel } = Collapse;

                    //mapping allegati
                    const fileList = []
                    helpDesk.single_ticket[0].allegati.map(async (allegato) => {
                        fileList.push({
                            uid: allegato.id,
                            name: <a href={encodeURI(bedominio + '/odoo/get_file_ticket?file_id=' + engine.encrypt({encrypted: '' + allegato.id}, 300000))} onClick={''}>{allegato.display_name}</a>,
                            status: 'done',
                            thumbUrl: 'https://i.imgur.com/8c3L7DY.png'
                        })
                    })

                    //mapping chatters
                    var comments = [         
                    ]

                    helpDesk.single_ticket[0].chatter_msg.map((messaggio) => {
                        if(messaggio.author_avatar == false){
                            messaggio.author_avatar = 'iVBORw0KGgoAAAANSUhEUgAAADQAAAA2CAIAAAAH0i23AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAMTSURBVGhD7Zh9W9owFEf9/t8OFRABAUVei0X0AYFt7rS/LLoNaW5YB39wnscINr339KZNUi9+nDBnuVjOcrGc5WI5y8VykNz3X7jvv+OOfXE0hEg5paR9f3/fbDaz2Ww8Ho9y+MDX1WrFodztGHKQpmmr1apWq1d/cX19fXNzg+i3HHeakRg5tMj38PAgiT1cXl5iv16vdTHu/GBsctKibbfbhWagPigy9BH1M8tBkiTkC5ET9Ox2u5xo9bPJEZ0a1Ot1lzYM5LiY5+dnVd3FCsAgp0ufTqeVSsWlDQa/TqejIIoWgk2O2YEc4QP6GepNENPI2uRob29vXTY73BLlVo7Zy6Wy8/b2dpaLgjXNxQrDLNdoNFwqO6VX7hC50h+Ik5Y7ZCrZbrflyjWbTZfKCDurcpcv5NgCuWxGarUaEcqSA+Tili9OYfni9BKXL3h8fIxbW7kfkCuxcoReLBbsf1zCYDiFq1IEhQrBJqc7mtnEVDw6w3K5NI0p2OQAObZ0Jjm4u7vjRHBRwjAPK7AKmbbpbE7n87nOdYHCiKkc3N/fB8qpm6ZfcFHCMMsBOViImFSVfj/I8Y4dYQaRcrQh76104Omhs/VREJFyJCssHma62+LMwPxAqNUHEu+Z85AbDAb01AQk8jChhMq52Hl0kvHApmna7/f3jCyHeJeeTCbMcP6BEIpZSLGcYvmIVItdE+NVuE7IW4PLDcAKwZ3gQ2WORZb75HwU2vV6PRwOWbxJpqxWdBb7Bl79FfZz/J18KedPRms0GrHhiVhSd4IlC8br66vuReXKc/7JDrncKruL2URopdJFqz0QH41LpYr+WVFeCXg+5PyxvHP2j0G/wKv9t+SSV9yLPNEMjhdQK5ycP8ClUHDKLiG1pUIJuZWTJPGvP7T68CEHTBDswiP+iXQIn6vw9PSkDanI5PhFwXgYKTKd1O8/o6S0VGe1Wjk5fpghe72eHsajmHmywuRvGy8vL5kclWMe119dl6MiDcaQwbxgxj8dMyGfdrt9wex6UmYCJYp3onKAWCbnvp0YtVrtJyD2lJDh9tklAAAAAElFTkSuQmCC'
                        }
                        comments.push({
                            author: messaggio.author_id[1],
                            avatar: 'data:image/png;base64,' + messaggio.author_avatar,
                            content: <div dangerouslySetInnerHTML={{__html: messaggio.body.split('src="/web/image').join('src="' + dominio + '/web/image/').split('<a').join('<p').split(dominio + '/web#').join('').split('res.partner').join('')}} />,
                            datetime: moment(messaggio.date).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss')
                        })
                    })

                    const props = {
                        action: '',
                        listType: 'picture',
                        defaultFileList: [...fileList],
                        className: 'upload-list-inline',
                        showUploadList:{
                            howPreviewIcon: true, 
                            showDownloadIcon: false, 
                            showRemoveIcon: false, 
                            removeIcon: <div></div>, 
                            downloadIcon: <div></div>
                        },
                    };

                    return(
                        <div>
                        <Row justify="start">
                            <Col flex={'1%'} justify="start"></Col>
                            <Col flex={'auto%'} justify="start">
                                <Button onClick={open_ticket_list}type="primary" danger icon={<ArrowLeftOutlined />}>I miei Ticket</Button>
                            </Col>     
                        </Row>
    
                        <Grid>
                        <Grid.Row>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                        <Grid.Column width={3}></Grid.Column>
                        <Grid.Column width={3}></Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={3}></Grid.Column>
                        </Grid.Row>
                    
                        <Grid.Row>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={14} textAlign="center"> 
                                    <Steps>
                                        {
                                           
                                           def_status.reverse().map((status, index, array) => {
                                            if(index === 0){
                                                return (<Step status={status.status} title={status['1']} icon={<UserOutlined />} />)
                                            }
                                            if(index === array.length - 1){
                                                return (<Step status={status.status} title={status['1']} icon={<SmileOutlined />} />)
                                            }
                                            
                                            if(status.status === 'process'){
                                                return (<Step status={status.status} title={status['1']} icon={<LoadingOutlined />} />)
                                            } else {
                                                if(status.status === 'finish'){
                                                    return (<Step status={status.status} title={status['1']} icon={<CheckCircleTwoTone twoToneColor="#52c41a" />} />)
                                                } else {
                                                    return (<Step status={status.status} title={status['1']} icon={<HourglassOutlined />} />)
                                                }
                                            }
                                            
                                            
                                           })
                                        }
                                    </Steps> 
    
                        </Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>
                        </Grid>
                
                {/* inzio dati in viasualizzazione  */}
    
                        <Grid>

                        <Grid.Row>
                        {/* <Grid.Column width={1}></Grid.Column> */}
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Ticket N°</h4></Grid.Column>
                        <Grid.Column width={4}><h5>{helpDesk.single_ticket[0].id}</h5></Grid.Column>
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Helpdesk Team</h4></Grid.Column>
                        <Grid.Column width={4}><h5>{helpDesk.single_ticket[0].team_id[1]}</h5></Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>
    
                        <Grid.Row>
                    {/*   <Grid.Column width={1}></Grid.Column> */}
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Creato il </h4></Grid.Column>
                        <Grid.Column width={4}><h5>{(moment(helpDesk.single_ticket[0].create_date).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss') === 'Invalid Date' ? '' : moment(helpDesk.single_ticket[0].create_date).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss'))}</h5></Grid.Column>
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Customer email</h4></Grid.Column>
                        <Grid.Column width={4}><h5>{helpDesk.single_ticket[0].email}</h5></Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                    {/*   <Grid.Column width={1}></Grid.Column> */}
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Customer</h4></Grid.Column>
                        <Grid.Column width={4}><h5>{helpDesk.single_ticket[0].commercial_partner_id[1]}</h5></Grid.Column>
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Tipologia</h4></Grid.Column>
                        <Grid.Column width={4}><h5>{helpDesk.single_ticket[0].x_studio_tipologia}</h5></Grid.Column>
                        </Grid.Row>
    
                        <Grid.Row>
                    {/*  <Grid.Column width={1}></Grid.Column> */}
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Inizio On Hold</h4></Grid.Column>
                        <Grid.Column width={4}><h5>{(moment(helpDesk.single_ticket[0].x_studio_data_inizio_on_hold).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? '' : moment(helpDesk.single_ticket[0].x_studio_data_inizio_on_hold).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss'))}</h5></Grid.Column>
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Fine On Hold</h4></Grid.Column>
                        <Grid.Column width={4}><h5>{(moment(helpDesk.single_ticket[0].x_studio_data_fine_on_hold).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? '' : moment(helpDesk.single_ticket[0].x_studio_data_fine_on_hold).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss'))}</h5></Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>
    
                        <Grid.Row>
                    {/*  <Grid.Column width={1}></Grid.Column> */}
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Data di chiusura</h4></Grid.Column>
                        <Grid.Column width={4}><h5>{(moment(helpDesk.single_ticket[0].close_date).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? '' : moment(helpDesk.single_ticket[0].close_date).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss'))}</h5></Grid.Column>
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Preso in carico</h4></Grid.Column>
                        <Grid.Column width={4}><h5>{(moment(helpDesk.single_ticket[0].x_studio_working_in_progress).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? '' : moment(helpDesk.single_ticket[0].x_studio_working_in_progress).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss'))}</h5></Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>
    
                        <Grid.Row>
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Prorità</h4></Grid.Column>
                        <Grid.Column width={4}><h5>{helpDesk.single_ticket[0].ticket_type_id[1]}</h5></Grid.Column>
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Categoria</h4></Grid.Column>
                        <Grid.Column width={4}>
                            <div>
                            {
                            helpDesk.single_ticket[0].category.map((categoria) => {
                                return (<Tag color="default">{categoria.name}</Tag>)
                            })
                            }
                            </div>
                        </Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                        <Grid.Column width={3}><h4 id='giustificazione_destra'>Oggetto</h4></Grid.Column>
                        <Grid.Column width={4}><h5>{helpDesk.single_ticket[0].name}</h5></Grid.Column>
                        <Grid.Column width={3}>{(helpDesk.single_ticket[0].x_studio_tipologia === 'Incident' ? <div></div> : <h4 id='giustificazione_destra'>Effort</h4>)}</Grid.Column>
                        <Grid.Column width={4}>{(helpDesk.single_ticket[0].x_studio_tipologia === 'Incident' ? <div></div> : <h5>{helpDesk.single_ticket[0].x_studio_effort}</h5>)}</Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                        <Grid.Column width={3}>{(helpDesk.single_ticket[0].x_studio_tipologia === 'Incident' ? <div></div> : <h4 id='giustificazione_destra'>Due Date</h4>)}</Grid.Column>
                        <Grid.Column width={4}>{(helpDesk.single_ticket[0].x_studio_tipologia === 'Incident' ? <div></div> : <h5>{(moment(helpDesk.single_ticket[0].x_studio_due_date).add(1, 'hours').format('DD/MM/YYYY') === 'Invalid date' ? '' : moment(helpDesk.single_ticket[0].x_studio_due_date).add(1, 'hours').format('DD/MM/YYYY'))}</h5>)}</Grid.Column>
                        <Grid.Column width={3}>{(helpDesk.single_ticket[0].x_studio_tipologia === 'Incident' ? <div></div> : <h4 id='giustificazione_destra'>Stato</h4>)}</Grid.Column>
                        <Grid.Column width={4}>{(helpDesk.single_ticket[0].x_studio_tipologia === 'Incident' ? <div></div> : <h5>{helpDesk.single_ticket[0].x_studio_stato}</h5>)}</Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>

    
                        <Grid.Row>
                            <Grid.Column width={1}></Grid.Column>   
                            <Grid.Column width={14}>
                                    <Collapse bordered={false} expandIcon={({ isActive }) => <div><PaperClipOutlined style={{fontSize: '18px', color: '#ea515b'}} rotate={isActive ? 90 : 0} />   Totale Allegati: {helpDesk.single_ticket[0].allegati.length}</div>} >
                                    <Panel key="1">
   
                                        <Upload  {...props}>
                                        
                                        </Upload>
                                    </Panel>
                                </Collapse>
                            </Grid.Column>
                            <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>

                        <br></br><br></br>

                        <Grid.Row>
                        <Grid.Column width={1}></Grid.Column>   
                            <Grid.Column width={14}>
                                <Divider />
                            </Grid.Column>
                            <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                            <Grid.Column width={1}></Grid.Column>   
                            <Grid.Column width={7}><h2>Messaggio e Storico delle Comunicazioni</h2></Grid.Column>
                            <Grid.Column width={8}></Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                            <Grid.Column width={1}> </Grid.Column>
                            <Grid.Column width={14}>
                                <Form>
                                    <TextArea onChange={setMessaggioChatter} id="input_rossi_100p" rows={6} placeholder='Aggiungi un Messaggio' />
                                </Form></Grid.Column>   
                        </Grid.Row>

                        <Grid.Row>
                        <Grid.Column width={1}></Grid.Column>
                            <Grid.Column width={2}>
                                <Button disabled={null} onClick={crea_chatter} id='bottone_nuovo_ticket' type="primary" danger>Invia Messaggio<SendOutlined /></Button>
                            </Grid.Column>
                        <Grid.Column width={13}></Grid.Column> 
                        </Grid.Row>

                        <Grid.Row>
                        <Grid.Column width={1}></Grid.Column>   
                            <Grid.Column width={14}>
                                <Divider />
                            </Grid.Column>
                            <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>

                        

                        <Grid.Row>
                            <Grid.Column width={2}></Grid.Column>
                            <Grid.Column width={12}>   
                                <List
                                    dataSource={comments}
                                    header={''}
                                    itemLayout="horizontal"
                                    renderItem={props => <Comment {...props} />}
                                />
                            </Grid.Column> 
                            <Grid.Column width={2}></Grid.Column>   
                        </Grid.Row>
    
                    </Grid>
                        </div>
                    
                    )
                } else {
                    // Caricamento
                    return(<div >
                        <SyncOutlined id="caricamento_icona" style={{ fontSize: 48, color: "red" }} spin />
                        <h3 id="caricamento_scritta">Caricamento in corso...</h3>
                       </div>)
                }         
            } else {
                // Caricamento
                return(<div >
                    <SyncOutlined id="caricamento_icona" style={{ fontSize: 48, color: "red" }} spin />
                    <h3 id="caricamento_scritta">Caricamento in corso...</h3>
                   </div>)
            }
        

        }

  } else {
      if(NeedClearStatus){
          clear_ticket()
          SetNeedClearStatus(false)
      }
    return (<div></div>)
  }     
    
}




const mapStateToProps = state => ({
    login: state.loginPage,
    helpDesk: state.helpDesk
})

const mapDispatchToProps = dispatch => ({
    TryDoLoginOdoo: (useroggetto, password) => dispatch(TryDoLoginOdoo(useroggetto, password)),
    get_all_mytickets: (id, su_ids, isAdmin) => dispatch(get_all_mytickets(id, su_ids, isAdmin)),
    get_single_ticket: (id) => dispatch(get_single_ticket(id)),
    get_possible_status_team: (team_id) => dispatch(get_possible_status_team(team_id)),
    get_team_priority: (team_id) => dispatch(get_team_priority(team_id)),
    get_team_category: (team_id) => dispatch(get_team_category(team_id)), 
    get_available_code: (user_id) => dispatch(get_available_code(user_id)),
    create_ticket: (name,team_id,tipologia,user_id,type,tagid,company,display_name,email,descrizione,allegati,crapprovata) => dispatch(create_ticket(name,team_id,tipologia,user_id,type,tagid,company,display_name,email,descrizione,allegati, crapprovata)),   
    create_chatter_msg: (ticket_id,messaggio,user_id) => dispatch(create_chatter_msg(ticket_id,messaggio,user_id)),
    clear_new_ticket: () => dispatch(clear_new_ticket()),
    clear_ticket: () => dispatch(clear_ticket()),
    change_header: (text) => dispatch(change_header(text)),
})

export default {
    Application: connect(mapStateToProps, mapDispatchToProps)(HelpDeskMainPage),
}




